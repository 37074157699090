<template>
  <div class="login">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-6-tablet is-5-desktop is-4-widescreen">
              <!-- https://buefy.org/extensions/veevalidate/ -->
              <!-- https://codesandbox.io/s/nnqnonwn9p?from-embed -->
              <form @submit.prevent="handleSubmit">
                <div class="content">
                  <h3 class="has-text-centered">Login</h3>
                </div>
                <b-notification
                  v-if="isDemo"
                  aria-close-label="Close notification">
                  <b>Demo Accounts</b>
                    <div>Joe Doe</div>
                    <div>Email: joedoe@gmail.com</div>
                    <div>Password: password</div>
                    <br />
                    <div>Jane Smith</div>
                    <div>Email: janesmith@gmail.com</div>
                    <div>Password: password</div>
                </b-notification>
                <b-field
                  label="Email"
                  label-for="email"
                  :type="{'is-danger': errors.has('email')}"
                  :message="errors.first('email')">
                  <b-input
                    placeholder="e.g. name@email.com"
                    id="email"
                    type="email"
                    size="is-large"
                    icon="envelope"
                    v-model="email"
                    name="email"
                    v-validate="'required|email'">
                  </b-input>
                </b-field>
                <b-field
                  label="Password"
                  label-for="password"
                  :type="{'is-danger': errors.has('password')}"
                  :message="errors.first('password')">
                  <b-input
                    placeholder="*******"
                    id="password"
                    type="password"
                    size="is-large"
                    icon="lock"
                    password-reveal
                    v-model="password"
                    name="password"
                    v-validate="'required|min:5'">
                  </b-input>
                </b-field>
                <b-button
                  class="button is-primary is-large is-fullwidth"
                  native-type="submit"
                  icon-right="arrow-right"
                  :loading="submitBtnLoading">
                  Log in
                </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="third-section-background-img">
      <div class="column">
        <div class="homepage-third-section">
          <div>
            <img
              src="../assets/twork.png"
              alt="TWORK company logo"
            >
          </div>
          <h2 class="has-text-white is-size-2">
            The #1 platform for project management
          </h2>
        </div>
      </div>
      <div class="homepage-footer has-text-white">
        <div>
          © 2023 Twork by Qian Pan
        </div>
        <div>
          Terms & Privacy
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS } from '@/store/modules/workspace/workspace.constants';
import UserModel from '@/models/user.model';

export default {
  name: 'LoginView',
  data() {
    return {
      email: null,
      password: null,
      isDemo: false,
      submitBtnLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      WORKSPACE_CONSTANTS.GETTERS.OWNER_WORKSPACE,
    ]),
  },
  methods: {
    /* TODO: these async functions needs try/catch
    or have an error handler utility wrapper function */
    async handleSubmit() {
      this.submitBtnLoading = true;
      if (await this.isFormValid()) {
        this.login();
      } else {
        this.submitBtnLoading = false;
        this.$buefy.toast.open({
          message: 'Form is not valid! Please check the fields.',
          duration: 3000,
          type: 'is-danger',
          position: 'is-bottom'
        });
      }
    },
    async isFormValid() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    login() {
      const { email, password } = this;
      const userDTO = new UserModel({ email, password });
      this.$store.dispatch(AUTH_CONSTANTS.ACTIONS.AUTH_REQUEST, { userDTO })
        .then(() => {
          this.$router.push({ name: 'dashboard', params: { workspaceTitle: this.OWNER_WORKSPACE.title } });
        })
        .catch((error) => {
          this.submitBtnLoading = false;
          this.$buefy.toast.open({
            message: 'Your email or password is incorrect. Please try again.',
            duration: 3000,
            type: 'is-danger',
            position: 'is-bottom'
          });
          console.error(error);
        });
    },
  },
  mounted() {
    this.isDemo = !!this.$route.params.isDemo;
  }
};
</script>
