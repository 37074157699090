import { get } from 'lodash';
import store from '@/store';
import { CONSTANTS as WORKSPACE_CONSTANTS } from '@/store/modules/workspace/workspace.constants';

class UserModel {
  constructor(options = {}) {
    const {
      _id,
      email,
      password,
      fullName,
      workspace,
    } = options;

    this._id = _id;
    this.email = email;
    this.password = password;
    this.fullName = fullName;
    this.workspace = workspace
      || get(store.getters[WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE], '_id');
  }
}

export default UserModel;
